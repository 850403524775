import { useNavigate } from 'react-router-dom'
import axiosInstance from '@/services/api'
import { useAuth0 } from '@auth0/auth0-react'

type Params = {
  path: string
  method: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH'
  params?: Record<string, unknown> | URLSearchParams
  body?: Record<string, unknown>
}

export const useHttpRequest = () => {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${await getAccessTokenSilently()}`
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
          const accessToken = await getAccessTokenSilently()
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
          return axiosInstance(originalRequest)
        } catch (error) {
          console.error('Token refresh failed:', error)
          navigate('logout')
          return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    }
  )

  const fetch = async <T>({ method, path, body, params }: Params) => {
    switch (method) {
      case 'GET':
        return await axiosInstance.get<T>(path, { params })
      case 'POST':
        return await axiosInstance.post<T>(path, body, { params })
      case 'DELETE':
        return await axiosInstance.delete<T>(path, { params })
      case 'PUT':
        return await axiosInstance.put<T>(path, body, { params })
      case 'PATCH':
        return await axiosInstance.patch<T>(path, body, { params })
    }
  }

  return { fetch }
}
